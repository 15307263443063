import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    HeaderContainer, HeaderLogo, HeaderInfo,
    HeaderLink, HeaderImage,
    InfoText, InfoIcon, LogoText, InfoTextArrows, InfoIconArrow,
    InfoTextLogout, ButtonChange
} from './styles'
import axios from 'axios';


export default function Header({ dashPosition, setPosition }) {
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(true);

    const checkLoginStatus = () => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                // Verificar se o token é válido fazendo uma solicitação ao backend
                const response = axios.post('https://api.ltsmonitor.com.br/api/token/verify/', {
                    token: token
                });
                if (response.status === 200) {
                    setLoggedIn(true)
                }else{
                    setLoggedIn(false)
                }
            }else{
                setLoggedIn(false)
                navigate("/")
            }
        } catch (error) {
            console.log(error);
            setLoggedIn(false)
        }
    };

    const handleLogout = () => {
        // Remover o token do localStorage e atualizar o estado de login
        localStorage.removeItem('token');
        navigate("/")
    };

    

    useEffect(() => {
        checkLoginStatus();
        
        if (!loggedIn) {
            navigate("/")
        }
    }, [])

    return (
        <HeaderContainer>
            <HeaderLogo>
                <HeaderLink href="/">
                    <HeaderImage src="/images/logo_am4.png" />
                    <LogoText>LTS - Monitor</LogoText>
                </HeaderLink>
            </HeaderLogo>
            <HeaderInfo>
                <ButtonChange className={dashPosition == 1 ? "active" : ""} onClick={() => setPosition(1)} >ÚLTIMAS 24H</ButtonChange>
                <ButtonChange className={(dashPosition == 2 || dashPosition == 3) ? "active" : ""} onClick={() => setPosition(2)}>ÚLTIMOS 7 DIAS</ButtonChange>
                <ButtonChange className={(dashPosition == 4 || dashPosition == 5) ? "active" : ""} onClick={() => setPosition(4)}>ÚLTIMOS 30 DIAS</ButtonChange>
                <InfoTextLogout onClick={() => handleLogout()}>Sair</InfoTextLogout>
            </HeaderInfo>
        </HeaderContainer>
    )
}

