import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    top: 75px;
    padding: 40px 100px;
    visibility: hidden;
    
    &.active{
        visibility: initial;
    }
`

export const Title = styled.p`
    color: #fff;
    font-weight: 700;
    font-size: 20px;
`


export const Text = styled.p`
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    span{
        color: #f9e036;
        font-weight: 700;
    }

    b{
        font-weight: 700;
    }
`

