import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const FooterContainer = styled.footer`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999999999;
    position: absolute;
    background: #222222;
    width: 100%;
    bottom: 0px;
    height: 75px;

    @media screen and (max-width: 768px)  {
        height: 160px;
    }
`

export const BoxInfo = styled.div`
    display:flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    gap: 30px;

    @media screen and (min-width: 2000px)  {
        height: 65px;
    }

    @media screen and (max-width: 768px)  {
        justify-content: center;
    }
`

export const ContentInner = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0px 40px;

    @media screen and (max-width: 768px)  {
        flex-direction: column;
    }
`



export const ButtonChange = styled.a`
    color: #fff;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding: 0px 30px;
    height: 35px;
    align-items: center;
    font-weight: 500;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;

    &.active{
        color: ${COLORS.black};
        background-color: #fff;
    }

    @media screen and (min-width: 2000px)  {
        font-size: 24px;
    }

    @media screen and (max-width: 768px)  {
        font-size: 11px;
    }
`
