import React, { useState, useEffect } from 'react'
import { ReportContainer, BackButton } from './styles'

import Header from "../Header"

import { getReport, MEDIA_PATH } from '../../services/api'

export default function Report() {
    const [report, setReport] = useState()

    useEffect(() => {
        getReport().then((response) => {
            setReport(response.data);
        });
    }, [])

    return (

        <>
            <Header />
            <ReportContainer>
                {
                    report && <embed src={MEDIA_PATH + report?.image + "#toolbar=0&zoom=200"} type="application/pdf" />
                }

                <BackButton href="/dashboard">Dashboard</BackButton>
            </ReportContainer>

        </>

    )
}
