
import React, { Component, useEffect, useState } from 'react';
import Header from "../Header"
import Footer from "../Footer"
import Glossario from "../Glossario"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { BackButton, AsideButton, Counter } from './styles'

const TIME_FADE = 30000
const TIME_TRANSITION = 30
const MAX_STEP = 5;
export default function Dashboard() {
    const [position, setPosition] = useState(1);
    const [clock, setClock] = useState(1);
    const [automatic, setAutomatic] = useState(true);
    const [glossario, setGlossario] = useState(false);

    const [dash, setDash] = useState([
        {
            "link": "https://app.powerbi.com/view?r=eyJrIjoiOWY2YzY1ZGItNWYzZC00Njk5LWI3ODgtYWRmMWRkNjdjODdhIiwidCI6IjE1YTY3ZDhmLTA4Y2UtNDE0Mi1hOWNmLTZlZjcxMTk4YTVjMiJ9&pageName=ReportSection",
            "position": 1,
            "name": "ltsmonitor-24horas",
            "special": false,
        },
        {
            "link": "https://app.powerbi.com/view?r=eyJrIjoiOWY2YzY1ZGItNWYzZC00Njk5LWI3ODgtYWRmMWRkNjdjODdhIiwidCI6IjE1YTY3ZDhmLTA4Y2UtNDE0Mi1hOWNmLTZlZjcxMTk4YTVjMiJ9&pageName=ReportSectiona76f05c73b437f30e4fa",
            "position": 2,
            "name": "7 dias - parte 1",
            "special": false,
        },
        {
            "link": "https://app.powerbi.com/view?r=eyJrIjoiOWY2YzY1ZGItNWYzZC00Njk5LWI3ODgtYWRmMWRkNjdjODdhIiwidCI6IjE1YTY3ZDhmLTA4Y2UtNDE0Mi1hOWNmLTZlZjcxMTk4YTVjMiJ9&pageName=ReportSection34e251e5eb3caa6d92a1",
            "position": 3,
            "name": "7 dias - parte 2",
            "special": false,
        },
        {
            "link": "https://app.powerbi.com/view?r=eyJrIjoiOWY2YzY1ZGItNWYzZC00Njk5LWI3ODgtYWRmMWRkNjdjODdhIiwidCI6IjE1YTY3ZDhmLTA4Y2UtNDE0Mi1hOWNmLTZlZjcxMTk4YTVjMiJ9&pageName=ReportSection935665b126e74553e503",
            "position": 4,
            "name": "30 dias - parte 1",
            "special": false,
        },
        {
            "link": "https://app.powerbi.com/view?r=eyJrIjoiOWY2YzY1ZGItNWYzZC00Njk5LWI3ODgtYWRmMWRkNjdjODdhIiwidCI6IjE1YTY3ZDhmLTA4Y2UtNDE0Mi1hOWNmLTZlZjcxMTk4YTVjMiJ9&pageName=ReportSection398520cea20e1736c8a9",
            "position": 5,
            "name": "30 dias - parte 2",
            "special": false,
        }

    ])


    useEffect(() => {

        const interval = setInterval(() => {
            if (automatic) {
                updateStep()
            }

        }, TIME_FADE);

        const clock = setInterval(() => {
            if (automatic) {
                setClock((prevCount) => (prevCount === TIME_TRANSITION ? 1 : prevCount + 1));
            }
        }, 1000);

        return () => {
            clearInterval(interval);
            clearInterval(clock);
        }
    }, [automatic])


    const updateStep = () => {
        // this.updateClock()
        setPosition((prevCount) => (prevCount === MAX_STEP ? 1 : prevCount + 1));
    }

    const changeOrientation = (value) => {
        setAutomatic(value)
        setClock(1)
        setGlossario(false)
    }

    const updateForcedPosition = (value) => {
        setAutomatic(false)
        setClock(1)
        setPosition(value)
        setGlossario(false)
    }

    return (
        <>
            <Header dashPosition={position} setPosition={updateForcedPosition} />
            <div className='iframes' >
                {
                    !glossario &&
                    dash.map((item, index) => {
                        return (
                            <div key={index}>
                                <iframe className={position == item.position ? "active" : ""} title={item.name}
                                    width="100%" height="100%"
                                    src={item.link}
                                    frameborder="0" allowFullScreen="true">
                                </iframe>

                            </div>
                        )
                    })
                }
                {
                    glossario && <Glossario />
                }
            </div>
            {automatic && <Counter>{clock}</Counter>}
            <Footer
                setAutomatic={changeOrientation}
                automatic={automatic}
                setPosition={updateForcedPosition}
                position={position}
                maxStep={MAX_STEP}
                setGlossario={setGlossario}
                glossario={glossario}
            />
        </>

    )

}


