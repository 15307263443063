import React, { Component, useEffect, useState } from 'react';
import {
    Container, Title, Text
} from './styles'


export default function Glossario() {
    return (
        <Container className="active"> 
            <Title>GLOSSÁRIO DE TERMOS E INDICADORES UTILIZADOS</Title>
            <Text>
                <span>1. Alcance -</span> Teor do alcance é baseado no sentimento das menções. Conforme base de seguidores do perfil e índice de interatividade é calculado o alcance;
                <br/><span>2. Usuários mais engajados – </span>Pessoas que realizarem mais publicações e ou conteúdos de grande relevância a respeito dos tópicos do nosso monitoramento.
                <br/><span>3. Menções – </span>Publicações, ações, comentários, compartilhamentos que tenham os termos monitorados;
                <br/><span>4. Menções por tipo – </span>A sentimentalização de menções se dará da seguinte forma: considerado positivo menções que agreguem valor a marca. Negativas aquelas que exercem
                juízo negativo a marca. Neutras são as menções que não exercem juízo de valor sobre a marca;
                <br/><span>5. Temas mais citados –</span> Assuntos que foram mais relevantes por quantidade de menções ou por divulgação em sites/portais, além de perfis de alto impacto.
                <br/><span>6. Influenciadores mais engajados –</span> Perfis de relevância que publicaram a respeito dos termos monitorados;
                <br/><span>7. Conteúdo de maior relevância –</span> Conteúdos publicados por quaisquer usuários que tenham obtido destaque significativo no período;
                <br/><span>8. Menção por estado –</span> Origem de publicações passíveis de identificação via geolocalização;
                <br/><span>9. Insights –</span> Análises periódicas que busquem a conversão de dados em informação relevante sobre o período analisado.
                
                <br/><br/><b>10. Tendência dos temas:</b>
                <br/><span>10.1 – Pilar – </span>Tema ou tag utilizada para classificar a menção.
                <br/><span>10.2 – Impacto Total –</span> Alcance da publicação realizada;
                <br/><span>10.3 - Inserções total – </span>Número de menções;
                <br/><span>10.4 – IP – </span>Índice de crescimento ou decrescimento de abrangência
                <br/><span>10.5 - Tendência – </span>Variável projetiva de crescimento ou redução daquele tema frente aos demais;
                
                <br/><br/><b>11. Publicações de deputados sobre a CPI:</b>
                <br/><span>11.1 – Total de publicações e interações – </span>Número de menções daquele parlamentar sobre a temática; 
                <br/><span>11.2 - Interações Totais – </span> Volume de comentários, curtidas, compartilhamentos e cliques. 
            </Text>
        </Container>
    )
}

