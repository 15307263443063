import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const HeaderContainer = styled.header`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999999999;
    position: absolute;
    background: #222222;
    width: 100%;
    top: 0px;
    height: 75px;

    @media screen and (max-width: 768px)  {
        flex-direction: column;
        height: 130px;
    }
`

export const HeaderLogo = styled.div`
    padding-left: 30px;
`

export const HeaderLink = styled.a`
    display:flex;
    align-items: center;
    text-decoration: none;


`

export const HeaderImage = styled.img`
    width: 100px;

    @media screen and (min-width: 2000px)  {
        width: 125px;
    }

`

export const HeaderInfo = styled.div`
    display:flex;
    flex-direction: row;
    height: 50px;
    padding-right: 25px;
    align-items: center;
    gap: 20px;

    @media screen and (min-width: 2000px)  {
        height: 65px;
    }

    @media screen and (max-width: 768px)  {
        gap: 10px;
        padding-right: 0px;
    }
`


export const InfoIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 15px;
`

export const InfoIconArrow = styled.img`
    width: 10px;
    height: 10px;
    margin: 0px 5px;

    @media screen and (min-width: 2000px)  {
        width: 20px;
        height: 20px;
    }
`

export const InfoText = styled.p`
    color: ${COLORS.text};

    @media screen and (min-width: 2000px)  {
        font-size: 24px;
    }
`

export const InfoTextLogout = styled(InfoText)`
    color: ${COLORS.text};
    margin-left: 30px;
    font-weight: 700;
    cursor: pointer;

    @media screen and (min-width: 2000px)  {
        font-size: 24px;
    }

    @media screen and (max-width: 768px)  {
        display:none;
    }
`

export const InfoTextArrows = styled.p`
    color: ${COLORS.text};

    @media screen and (min-width: 2000px) {
        font-size: 24px;
    }
`

export const LogoText = styled.p`
    color: ${COLORS.text};
    font-weight: 500;
    font-size: 19px;
    margin-left: 20px;

    @media only screen and (min-width: 2000px) {
        font-size: 30px;
    }
`

export const ButtonChange = styled.a`
    color: #fff;
    text-transform: uppercase;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding: 0px 30px;
    height: 35px;
    align-items: center;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;

    
    &.active{
        color: ${COLORS.black};
        background-color: #fff;
    }

    @media screen and (min-width: 2000px)  {
        font-size: 24px;
    }

    @media screen and (max-width: 768px)  {
        font-size: 11px;
        padding: 0px 15px;
    }
`