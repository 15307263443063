import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    LoginContainer, LoginBox,
    LoginLabel, LoginInput,
    LoginButton, LoginTitle,
    LoginGroup, LoginImage,
    LoginButtonGroup, LoginError
} from './styles'

export default function Login() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const handleLogin = async (e) => {
        if(!username || !password){
            setError(true)
            return false
        }

        try {
            const response = await axios.post('https://api.ltsmonitor.com.br/api/token/', { username, password });
            if (response.status === 200) {
                const token = response.data.access;

                // Salvar o token no localStorage
                localStorage.setItem('token', token);
                navigate('/dashboard')
            }
        } catch (error) {
            setError(true)
            return false
        }
    };

    // const handleLogin = () => {
    //     setError(false)
    //     if ((user == "admin" && password == "3Ket5r^r&DAhZ8") ||
    //         (user == "lts" && password == "xgi9oW2OL15YQ!")
    //     ) {
    //         setToken()
    //         navigate('/dashboard')
    //     } else {
    //         setError(true)
    //     }
    // }

    return (
        <>
            <LoginContainer>
                <LoginBox>
                    <LoginImage src="/images/logo_am4.png" />
                    <LoginTitle>LTS Monitor</LoginTitle>
                    <LoginGroup>
                        <LoginLabel>Usuário</LoginLabel>
                        <LoginInput onChange={(ev) => setUsername(ev.target.value)} />
                    </LoginGroup>

                    <LoginGroup>
                        <LoginLabel>Senha</LoginLabel>
                        <LoginInput type="password" onChange={(ev) => setPassword(ev.target.value)} />
                    </LoginGroup>

                    {
                        error && <LoginError>Usuário ou Senha inválido</LoginError>
                    }

                    <LoginButtonGroup>
                        <LoginButton onClick={() => handleLogin()} >Entrar</LoginButton>
                    </LoginButtonGroup>
                </LoginBox>
            </LoginContainer>

        </>

    )
}
