import styled from "styled-components";
import { COLORS } from "../../styles/colors";

export const ReportContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #2b2b2b;
    position: relative;

    embed{
        width: 100%;
        height: 100%;
        margin-top: 150px;
    }

`



export const BackButton = styled.a`
      display: flex;
    position: absolute;
    bottom: 30px;
    left:40px;
    align-items: center;
    background-color: #fff;
    color: #222;
    height: 35px;
    padding: 0px 30px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 17px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border: 2px solid #222222b8;
    gap: 10px;
    transition: 0.5s all;
    text-decoration:none;

    @media screen and (max-width: 768px)  {
       margin: 0 auto;
       bottom: 60px;
    }
    
    :hover{
        transform: scale(1.05);
    }
`



