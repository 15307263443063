import axios from "axios";

export const MEDIA_PATH = "https://api.ltsmonitor.com.br"

const api = axios.create({
    baseURL: "https://api.ltsmonitor.com.br/api/",
});

export default api;


export function getReport(){
    const headers =  { 'Authorization': 'Api-Key A8L6wmPF.HOP1qJj0Sh2eh6ZPGJ1FdL63Ey63WgcJ' }
    return api.get(`reports`, {headers})
}