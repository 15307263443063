import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearToken } from '../../utils'
import {
    FooterContainer, BoxInfo,
    InfoTextLogout, ButtonChange, ContentInner
} from './styles'


export default function Footer({ setAutomatic, automatic, position, setPosition, maxStep, setGlossario, glossario }) {
    const navigate = useNavigate();

    return (
        <FooterContainer>
            <ContentInner>
                <BoxInfo>
                    <ButtonChange href="/report" className='active'>Report Semanal</ButtonChange>
                    <ButtonChange className={glossario ? "active" : ""} onClick={() => setGlossario(!glossario)}>Glossário</ButtonChange>
                </BoxInfo>
                <BoxInfo>
                    {position > 1 && <ButtonChange className='active' onClick={() => setPosition(position - 1)} >Anterior</ButtonChange>}
                    {position < maxStep && <ButtonChange className='active' onClick={() => setPosition(position + 1)}>Próxima</ButtonChange>}
                </BoxInfo>
                <BoxInfo>
                    <ButtonChange onClick={() => setAutomatic(false)} className={!automatic ? "active" : ""}>Tela Estática</ButtonChange>
                    <ButtonChange onClick={() => setAutomatic(true)} className={automatic ? "active" : ""}>Tela Dinâmica</ButtonChange>
                </BoxInfo>
            </ContentInner>


        </FooterContainer>
    )
}

